<template>
  <div id="app">
    <Tabs></Tabs>
    <div class="content_box">
      <div class="content">
        <!-- 标题 -->
        <div class="title_box">
          <img src="../../../../assets/img/dingwei.png" alt="" />
          <div @click="goPrev">做题记录&nbsp;&nbsp;>&nbsp;&nbsp;</div>
          {{ this.$route.params.name }}
        </div>

        <!-- 时间轴 -->
        <!-- <div class="time" style="width:1200px"> -->
        <el-timeline>
          <el-timeline-item
            :timestamp="item.updatetime"
            placement="top"
            color="#3E7EFF"
            v-for="item in paper_list"
            :key="item.id"
          >
            <el-card>
              <!-- <div class="time_line_content"> -->
              <img src="../../../../assets/img/paper.png" alt="" />
              <div class="paper_name">
                {{ item.paper_title }}
              </div>
              <!-- 时间/得分 -->
              <div class="time_score">得分:&nbsp;{{ item.score }}分</div>
              <button
                class="info_btn"
                type="button"
                @click="goCard(item)"
                v-if="item.is_finish == 1"
              >
                查看详情
              </button>
              <button
                class="info_btn"
                type="button"
                @click="goPaperInfo(item)"
                v-else
              >
                继续做题
              </button>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <!-- </div> -->
      </div>
      <Foot></Foot>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  props: {},
  data() {
    return {
      paper_list: [], // 试卷列表
    };
  },
  computed: {},
  beforeCreate() {},
  created() {
    this.getDetail();
  },
  mounted() {},
  watch: {},
  methods: {
    // 返回上一页
    goPrev() {
      this.$router.go(-1);
    },
    //   获取详情单
    getDetail() {
      let data = {
        chapter_category: this.$route.params.chapter_id,
        paper_id: this.$route.params.id, // 试卷id
      };
      api.record_list(data).then((res) => {
        if (parseInt(res.data.code) == 1) {
          this.paper_list = res.data.data.list;
          res.data.data.list.forEach((ele) => {
            // console.log("ele====", ele.score);
            // 字符串截取分数小数点后的字符
            function getCaption(obj) {
              var index = obj.lastIndexOf(".");
              obj = obj.substring(index + 1, obj.length);
              //  // console.log(obj);
              return obj;
            }
            let str = ele.score;
            // 判断小数点后,有小数保留一位,没有取整
            if (getCaption(str) != "00") {
              // ele.score = parseInt(ele.score).toFixed(2);
              ele.score = Math.floor(ele.score * 100) / 100;
            } else {
              ele.score = parseInt(parseInt(ele.score));
            }
            // console.log(getCaption(str));
            // console.log("ele.score", ele.score);
          });
        }
        // console.log("试卷详情", res);
      });
    },
    // 去成绩单页
    goCard(item) {
      this.$router.push({
        name: "ReportCard",
        params: {
          is_pageid: 9,
          chapter_id: this.$route.params.chapter_id,
          chapter_name: this.$route.params.chapter_name,
          id: item.id,
          is_handpaper: 0,
          router_name: "做题记录",
        },
      });
    },
    goPaperInfo(item) {
      // console.log(item);
      // 开始做题之前先清除本地做题数据
      sessionStorage.removeItem("user");
      this.$router.push({
        name: "Introduce",
        params: {
          nav_id: 9,
          chapter_id: this.$route.params.chapter_id,
          chapter_name: sessionStorage.getItem("chapterName"),
          paper_id: item.paper_id,
          paper_name: item.paper_title,
          router_name: "做题记录",
        },
      });
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
#app {
  // background: #f5f5f5;
  // 内容区
  .content_box {
    width: 100%;
    // height: 100vh;
    background: #f5f5f5;
    // padding-bottom: 300px;
    .content {
      width: 1200px;
      margin: auto;
      overflow: hidden;
      // 标题
      .title_box {
        width: 1200px;
        height: 80px;
        background: #ffffff;
        border-radius: 12px;
        margin: 30px 0 23px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #202b3a;
        img {
          width: 16px;
          height: 18px;
          margin: 0 9px 0 40px;
        }
        div {
          color: #666;
          cursor: pointer;
        }
      }
      /deep/.el-timeline {
        margin-bottom: 168px;
        .el-timeline-item {
          /deep/.el-timeline-item__tail {
            margin-left: 2px;
          }
          /deep/.el-timeline-item__node {
            margin-left: 2px !important;
          }
          .el-card {
            // display: flex;
            .el-card__body {
              display: flex;
              // justify-content: space-between;
              align-items: center;
              img {
                width: 20px;
                height: 22px;
              }
              .paper_name {
                width: 520px;
                margin: 0 336px 0 9px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #202b3a;
              }
              .time_score {
                width: 95px;
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
              }
              .info_btn {
                width: 100px;
                height: 34px;
                background: #3e7eff;
                border-radius: 17px;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #ffffff;
                margin-left: 100px;
                cursor: pointer;
              }
            }
            // .time_line_content {
            //   width: 800px;
            //   background: pink;
            //   display: flex;

            //   img {
            //     width: 20px;
            //     height: 22px;
            //   }
            // }
          }
        }
      }
    }
  }
}
</style>
